"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMyOrdersPageData = exports.ALL_ACCOUNTS_KEY = exports.ONCHANGE_DELAY = exports.CUSTOMER_NOT_FOUND = exports.ORDER_ID = exports.INVOICE_ID = exports.EPageType = exports.ApiState = exports.MyOrdersPageStatesEnum = void 0;
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const cart_1 = require("@/Endpoints/cart");
const ordersHistory_1 = require("@/Endpoints/ordersHistory");
const Utils_1 = require("@/Utils");
const Hooks_1 = require("@/Hooks");
const user_1 = require("@/Endpoints/user");
const user_2 = require("@/Redux/Actions/user");
var MyOrdersPageStatesEnum;
(function (MyOrdersPageStatesEnum) {
    MyOrdersPageStatesEnum["LISTING"] = "listing";
    MyOrdersPageStatesEnum["ORDER"] = "order";
})(MyOrdersPageStatesEnum = exports.MyOrdersPageStatesEnum || (exports.MyOrdersPageStatesEnum = {}));
var ApiState;
(function (ApiState) {
    ApiState["CACHED"] = "cached";
    ApiState["UPDATED"] = "updated";
})(ApiState = exports.ApiState || (exports.ApiState = {}));
var EPageType;
(function (EPageType) {
    EPageType["INVOICES"] = "INVOICES";
    EPageType["ORDERS"] = "ORDERS";
})(EPageType = exports.EPageType || (exports.EPageType = {}));
exports.INVOICE_ID = 'invoiceId';
exports.ORDER_ID = 'saleId';
exports.CUSTOMER_NOT_FOUND = 'CustomerId not found';
exports.ONCHANGE_DELAY = 750;
exports.ALL_ACCOUNTS_KEY = '/haldex/invoices_history/filters/all_accounts';
const useMyOrdersPageData = (isInvoices = false) => {
    const [filterState, setFilterState] = (0, react_1.useState)({
        pageIndex: 0,
        pageSize: { id: 0, name: '25' },
        invoiceNumber: '',
        orderNumber: '',
        fromDate: null,
        toDate: null,
        selectedAccountOption: {
            id: 0,
            name: '',
            disabled: true
        },
        selectedPaymentStatusOption: { name: 'All', id: '0' },
        accountDropdownsData: [],
        isAccountDropdownDisabled: false,
        paymentStatusDropdown: [],
        filtersAreReady: false,
        isUrlParsed: false
    });
    const [ordersPageState, setOrdersPageState] = (0, react_1.useState)();
    const [customerServiceNotes, setCustomerServiceNotes] = (0, react_1.useState)();
    const [currentInvoice, setCurrentInvoice] = (0, react_1.useState)();
    const [currentOrder, setCurrentOrder] = (0, react_1.useState)();
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [isUpdating, setIsUpdating] = (0, react_1.useState)(false);
    const [isItemDetailsLoading, setIsItemDetailsLoading] = (0, react_1.useState)(false);
    const [totalPages, setTotalPages] = (0, react_1.useState)();
    const [showTagMenuHeader, setShowTagMenuHeader] = (0, react_1.useState)(false);
    const [isTagMenuOpen, setIstagMenuOpen] = (0, react_1.useState)(false);
    const [ordersHistory, setOrdersHistory] = (0, react_1.useState)([]);
    const [invoicesHistory, setInvoicesHistory] = (0, react_1.useState)([]);
    const [apiState, setApiState] = (0, react_1.useState)(ApiState.CACHED);
    const [warningMessage, setWarningMessage] = (0, react_1.useState)();
    const [errorMessage, setErrorMessage] = (0, react_1.useState)();
    const [firstRender, setFirstRender] = (0, react_1.useState)(false);
    const [customerInputError, setCustomerInputError] = (0, react_1.useState)({ error: false, message: '' });
    const [isRedirecting, setIsRedirecting] = (0, react_1.useState)(false);
    const prevOrdersPageStateRef = (0, react_1.useRef)(ordersPageState);
    const initialData = (0, Hooks_1.useInitialData)();
    const isNorthAmerica = (0, react_redux_1.useSelector)((state) => state.initialData.isNorthAmerica);
    const userData = (0, Hooks_1.useUserData)();
    const { isDesktopSmall4 } = (0, Hooks_1.useDevice)();
    const { t } = (0, Hooks_1.useTranslate)();
    const history = (0, react_router_dom_1.useHistory)();
    const { pageIndex, pageSize, orderNumber, invoiceNumber, fromDate, toDate, selectedAccountOption, selectedPaymentStatusOption, paymentStatusDropdown, accountDropdownsData } = filterState;
    (0, react_1.useEffect)(() => {
        var _a;
        const url = new URL((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.toString());
        for (const pair of url.searchParams.entries()) {
            if (pair[0] === exports.INVOICE_ID) {
                setOrdersPageState(MyOrdersPageStatesEnum.ORDER);
                window.history.replaceState({}, '', `${window === null || window === void 0 ? void 0 : window.location.pathname}?${exports.INVOICE_ID}=${pair[1]}`);
                setIsItemDetailsLoading(true);
                (0, ordersHistory_1.getInvoiceDetails)(`?${exports.INVOICE_ID}=${pair[1]}`)
                    .then(res => {
                    setCurrentInvoice(res.data);
                })
                    .finally(() => setIsItemDetailsLoading(false));
            }
            if (pair[0] === exports.ORDER_ID) {
                setOrdersPageState(MyOrdersPageStatesEnum.ORDER);
                setIsItemDetailsLoading(true);
                (0, ordersHistory_1.getOrderDetails)(`?${exports.ORDER_ID}=${pair[1]}`)
                    .then(res => {
                    setCurrentOrder(res.data);
                })
                    .finally(() => setIsItemDetailsLoading(false));
            }
        }
        if (isNorthAmerica) {
            (0, cart_1.getCustomerServiceNotes)(initialData === null || initialData === void 0 ? void 0 : initialData.continent).then(res => {
                var _a;
                setCustomerServiceNotes((_a = res.data) === null || _a === void 0 ? void 0 : _a.notes);
            });
        }
    }, []);
    (0, react_1.useEffect)(() => {
        var _a;
        if (isRedirecting)
            return;
        const url = new URL((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.toString());
        const searchParams = url === null || url === void 0 ? void 0 : url.search;
        if (searchParams === '' || (!searchParams.includes(exports.ORDER_ID) && !searchParams.includes(exports.INVOICE_ID))) {
            setOrdersPageState(MyOrdersPageStatesEnum.LISTING);
        }
    });
    (0, react_1.useEffect)(() => {
        if (isDesktopSmall4) {
            setShowTagMenuHeader(true);
        }
        else {
            setShowTagMenuHeader(false);
        }
    }, [isDesktopSmall4]);
    const getParams = (pageType) => {
        var _a;
        const isInvoicesPage = pageType === EPageType.INVOICES;
        const params = new URLSearchParams();
        const paymentStatuses = (0, Utils_1.transactionStatusesDropdown)(t, isInvoices);
        params.append(Utils_1.ESearchParams.PAGE_SIZE, `${pageSize.name}`);
        params.append(Utils_1.ESearchParams.PAGE_INDEX, `${pageIndex}`);
        fromDate && params.append(Utils_1.ESearchParams.FROM, fromDate === null || fromDate === void 0 ? void 0 : fromDate.toISOString());
        toDate && params.append(Utils_1.ESearchParams.TO, toDate === null || toDate === void 0 ? void 0 : toDate.toISOString());
        if (isInvoicesPage) {
            invoiceNumber && params.append(Utils_1.ESearchParams.QUERY, invoiceNumber);
        }
        else {
            orderNumber && params.append(Utils_1.ESearchParams.QUERY, orderNumber);
        }
        const customerId = selectedAccountOption === null || selectedAccountOption === void 0 ? void 0 : selectedAccountOption.name;
        if (customerId && customerId !== t(exports.ALL_ACCOUNTS_KEY)) {
            params.append(Utils_1.ESearchParams.CUSTOMER_ID, selectedAccountOption.id);
        }
        if ((selectedPaymentStatusOption === null || selectedPaymentStatusOption === void 0 ? void 0 : selectedPaymentStatusOption.name) &&
            (selectedPaymentStatusOption === null || selectedPaymentStatusOption === void 0 ? void 0 : selectedPaymentStatusOption.name) !== paymentStatuses[0].name &&
            isInvoicesPage) {
            params.append(Utils_1.ESearchParams.STATUS, selectedPaymentStatusOption.name);
        }
        const url = new URL((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.toString());
        const searchParams = url === null || url === void 0 ? void 0 : url.search;
        if (!searchParams.includes(exports.ORDER_ID) && !searchParams.includes(exports.INVOICE_ID)) {
            history.push({
                pathname: location.pathname,
                search: `?${params.toString()}`
            });
        }
        return '?' + params.toString();
    };
    const getAccountDropdownData = (pageType) => {
        var _a, _b, _c, _d, _e;
        if (pageType === EPageType.INVOICES) {
            const paymentStatuses = (0, Utils_1.transactionStatusesDropdown)(t, isInvoices);
            setFilterState(prev => {
                return Object.assign(Object.assign({}, prev), { paymentStatusDropdown: paymentStatuses, selectedPaymentStatusOption: paymentStatuses[0] });
            });
        }
        if (((_a = userData === null || userData === void 0 ? void 0 : userData.userProfile) === null || _a === void 0 ? void 0 : _a.customerAddressType) === user_2.addressType.Billing) {
            (0, user_1.getShippingAccounts)().then(res => {
                if (res === null || res === void 0 ? void 0 : res.data) {
                    const initialOption = {
                        id: 'Initial',
                        name: t(exports.ALL_ACCOUNTS_KEY)
                    };
                    setFilterState(prev => {
                        return Object.assign(Object.assign({}, prev), { isAccountDropdownDisabled: false, accountDropdownsData: [initialOption, ...(0, Utils_1.generateAccountDropdownsData)(res.data.shipTos)], selectedAccountOption: initialOption, filtersAreReady: true });
                    });
                }
            });
        }
        else {
            const userInfo = {
                name: ((_c = (_b = userData === null || userData === void 0 ? void 0 : userData.userProfile) === null || _b === void 0 ? void 0 : _b.customerId) === null || _c === void 0 ? void 0 : _c.trim()) || '',
                id: ((_e = (_d = userData === null || userData === void 0 ? void 0 : userData.userProfile) === null || _d === void 0 ? void 0 : _d.customerId) === null || _e === void 0 ? void 0 : _e.trim()) || '',
            };
            setFilterState(prev => {
                return Object.assign(Object.assign({}, prev), { accountDropdownsData: [userInfo], selectedAccountOption: userInfo, isAccountDropdownDisabled: true, filtersAreReady: true });
            });
        }
    };
    const parseFiltersFromUrl = (pageType) => {
        const searchParams = new URLSearchParams(location === null || location === void 0 ? void 0 : location.search);
        const filteredSearchParams = new URLSearchParams();
        for (const param of searchParams.keys()) {
            if (Object.values(Utils_1.ESearchParams).includes(param)) {
                filteredSearchParams.set(param, searchParams.get(param));
            }
        }
        const filteredParamsString = filteredSearchParams.toString();
        filteredParamsString
            ? window.history.replaceState({}, '', `${location.pathname}?${filteredSearchParams.toString()}`)
            : window.history.replaceState({}, '', location.pathname);
        if (filteredParamsString) {
            const searchParamsToDispatch = (0, Utils_1.getSearchParams)(searchParams, paymentStatusDropdown, accountDropdownsData, pageType);
            setFilterState(prev => {
                return Object.assign(Object.assign({}, prev), searchParamsToDispatch);
            });
        }
        else {
            getParams(pageType);
        }
        setFilterState(prev => {
            return Object.assign(Object.assign({}, prev), { isUrlParsed: true });
        });
    };
    return {
        filterState,
        setFilterState,
        ordersPageState,
        setOrdersPageState,
        customerServiceNotes,
        currentInvoice,
        setCurrentInvoice,
        currentOrder,
        setCurrentOrder,
        isLoading,
        setIsLoading,
        totalPages,
        setTotalPages,
        showTagMenuHeader,
        isTagMenuOpen,
        setIstagMenuOpen,
        ordersHistory,
        setOrdersHistory,
        invoicesHistory,
        setInvoicesHistory,
        isNorthAmerica,
        apiState,
        setApiState,
        isUpdating,
        setIsUpdating,
        warningMessage,
        setWarningMessage,
        errorMessage,
        setErrorMessage,
        firstRender,
        setFirstRender,
        customerInputError,
        setCustomerInputError,
        getParams,
        getAccountDropdownData,
        parseFiltersFromUrl,
        prevOrdersPageStateRef,
        isItemDetailsLoading,
        setIsItemDetailsLoading,
        isRedirecting,
        setIsRedirecting
    };
};
exports.useMyOrdersPageData = useMyOrdersPageData;
