"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcAverageCenter = exports.generateMapFilters = exports.getZoomLevel = exports.findMostRemotePins = exports.calculateDistance = exports.degToRad = exports.googleMapsDirectionUrl = exports.googleMapsLocation = exports.ZOOM_LEVEL = void 0;
var ZOOM_LEVEL;
(function (ZOOM_LEVEL) {
    ZOOM_LEVEL[ZOOM_LEVEL["CONTINENT"] = 5] = "CONTINENT";
    ZOOM_LEVEL[ZOOM_LEVEL["COUNTRY"] = 7] = "COUNTRY";
    ZOOM_LEVEL[ZOOM_LEVEL["REGION"] = 8] = "REGION";
    ZOOM_LEVEL[ZOOM_LEVEL["CITY"] = 10] = "CITY";
    ZOOM_LEVEL[ZOOM_LEVEL["STATE"] = 7] = "STATE";
    ZOOM_LEVEL[ZOOM_LEVEL["STREET"] = 14] = "STREET";
})(ZOOM_LEVEL = exports.ZOOM_LEVEL || (exports.ZOOM_LEVEL = {}));
const googleMapsLocation = (mapRef) => {
    var _a, _b, _c, _d, _e, _f;
    if (mapRef) {
        return {
            latMax: (_a = mapRef.getBounds()) === null || _a === void 0 ? void 0 : _a.getNorthEast().lat(),
            latMin: (_b = mapRef.getBounds()) === null || _b === void 0 ? void 0 : _b.getSouthWest().lat(),
            lngMax: (_c = mapRef.getBounds()) === null || _c === void 0 ? void 0 : _c.getNorthEast().lng(),
            lngMin: (_d = mapRef.getBounds()) === null || _d === void 0 ? void 0 : _d.getSouthWest().lng(),
            newCenterLat: (_e = mapRef === null || mapRef === void 0 ? void 0 : mapRef.getCenter()) === null || _e === void 0 ? void 0 : _e.lat(),
            newCenterLng: (_f = mapRef === null || mapRef === void 0 ? void 0 : mapRef.getCenter()) === null || _f === void 0 ? void 0 : _f.lng(),
            newZoom: mapRef === null || mapRef === void 0 ? void 0 : mapRef.getZoom()
        };
    }
};
exports.googleMapsLocation = googleMapsLocation;
const googleMapsDirectionUrl = (lat, lng) => {
    return `https://www.google.com/maps/dir/?api=1&origin=&destination=${lat},${lng}`;
};
exports.googleMapsDirectionUrl = googleMapsDirectionUrl;
// Function to convert degrees to radians
const degToRad = (degrees) => {
    return degrees * (Math.PI / 180);
};
exports.degToRad = degToRad;
const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in kilometers
    const dLat = (0, exports.degToRad)(lat2 - lat1);
    const dLon = (0, exports.degToRad)(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((0, exports.degToRad)(lat1)) * Math.cos((0, exports.degToRad)(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
};
exports.calculateDistance = calculateDistance;
const findMostRemotePins = (pins) => {
    let maxDistance = 0;
    let remotePins = [];
    let distance = 0;
    for (let i = 0; i < pins.length - 1; i++) {
        const pin1 = pins[i];
        for (let j = i + 1; j < pins.length; j++) {
            const pin2 = pins[j];
            const currDistance = (0, exports.calculateDistance)(pin1.latitude, pin1.longitude, pin2.latitude, pin2.longitude);
            if (currDistance > maxDistance) {
                maxDistance = currDistance;
                remotePins = [pin1, pin2];
                distance = maxDistance;
            }
        }
    }
    return { remotePins, distance };
};
exports.findMostRemotePins = findMostRemotePins;
const getZoomLevel = (remotePins, number) => {
    switch (true) {
        case number > 120 || (remotePins === null || remotePins === void 0 ? void 0 : remotePins.distance) > 750:
            return ZOOM_LEVEL.CONTINENT;
        case number > 70 || (remotePins === null || remotePins === void 0 ? void 0 : remotePins.distance) > 400:
            return ZOOM_LEVEL.COUNTRY;
        case (remotePins === null || remotePins === void 0 ? void 0 : remotePins.distance) > 200:
            return ZOOM_LEVEL.REGION;
        case number > 1 || (remotePins === null || remotePins === void 0 ? void 0 : remotePins.distance) > 50:
            return ZOOM_LEVEL.CITY;
        default:
            return ZOOM_LEVEL.STREET;
    }
};
exports.getZoomLevel = getZoomLevel;
const generateMapFilters = (filters) => {
    return Object.entries(filters)
        .map(([key, value]) => {
        if (Array.isArray(value)) {
            return value
                .map((item, index) => `&${key}[${index}]=${(item === null || item === void 0 ? void 0 : item.id) || item}`)
                .join('');
        }
        else if (typeof value === 'boolean') {
            return value ? `&${key}=true` : '';
        }
        return '';
    })
        .join('');
};
exports.generateMapFilters = generateMapFilters;
const calcAverageCenter = (items) => {
    let sumLatitude = 0;
    let sumLongitude = 0;
    for (const item of items) {
        sumLatitude += item === null || item === void 0 ? void 0 : item.latitude;
        sumLongitude += item === null || item === void 0 ? void 0 : item.longitude;
    }
    const avgLatitude = sumLatitude / items.length;
    const avgLongitude = sumLongitude / items.length;
    return { lat: avgLatitude, lng: avgLongitude };
};
exports.calcAverageCenter = calcAverageCenter;
