"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.superStoreClick = exports.filterPartnerFinder = exports.searchPartnerFinder = exports.openCompanyDetailsMap = exports.openCompanyDetails = exports.viewItemListEvent = exports.selectItemEvent = exports.viewItemEvent = exports.purchaseEvent = exports.addShippingInfoEvent = exports.commonDataLayerEvent = void 0;
const types_1 = require("@/DataLayer/types");
const helpers_1 = require("@/DataLayer/helpers");
const commonDataLayerEvent = (itemsArray, eventName, listName) => {
    var _a, _b, _c, _d;
    const formedItemsArray = (0, helpers_1.formItemsArray)(itemsArray, listName, false, true);
    const { value, currency } = (0, helpers_1.calcCurrencyAndValue)(formedItemsArray, (_a = itemsArray === null || itemsArray === void 0 ? void 0 : itemsArray[0]) === null || _a === void 0 ? void 0 : _a.price);
    (0, helpers_1.pushEvent)({
        event: eventName,
        ecommerce: {
            currency: ((_d = (_c = (_b = itemsArray[0]) === null || _b === void 0 ? void 0 : _b.prices) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.currency) || currency,
            value,
            items: formedItemsArray
        }
    });
};
exports.commonDataLayerEvent = commonDataLayerEvent;
const addShippingInfoEvent = (itemsArray, shipping_tier = '', listName) => {
    var _a;
    const formedItemsArray = (0, helpers_1.formItemsArray)(itemsArray, listName);
    const { value, currency } = (0, helpers_1.calcCurrencyAndValue)(formedItemsArray, (_a = itemsArray === null || itemsArray === void 0 ? void 0 : itemsArray[0]) === null || _a === void 0 ? void 0 : _a.price);
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.ADD_SHIPPING_INFO,
        ecommerce: {
            currency,
            value,
            shipping_tier,
            items: formedItemsArray
        }
    });
};
exports.addShippingInfoEvent = addShippingInfoEvent;
const purchaseEvent = (itemsArray, shipping_tier = '', shipping_address = '', shipping = 0, tax = 0, transaction_id = '', listName) => {
    var _a;
    const formedItemsArray = (0, helpers_1.formItemsArray)(itemsArray, listName);
    const { value, currency } = (0, helpers_1.calcCurrencyAndValue)(formedItemsArray, (_a = itemsArray === null || itemsArray === void 0 ? void 0 : itemsArray[0]) === null || _a === void 0 ? void 0 : _a.price);
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.PURCHASE,
        ecommerce: {
            currency,
            value,
            shipping_tier,
            shipping_address,
            shipping,
            tax,
            transaction_id,
            items: formedItemsArray
        }
    });
};
exports.purchaseEvent = purchaseEvent;
const viewItemEvent = (itemsArray, listName) => {
    var _a;
    const formedItemsArray = (0, helpers_1.formItemsArray)(itemsArray, listName, true);
    const { value, currency } = (0, helpers_1.calcCurrencyAndValue)(formedItemsArray, (_a = itemsArray === null || itemsArray === void 0 ? void 0 : itemsArray[0]) === null || _a === void 0 ? void 0 : _a.price);
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.VIEW_ITEM,
        ecommerce: {
            currency,
            value,
            items: formedItemsArray
        }
    });
};
exports.viewItemEvent = viewItemEvent;
const selectItemEvent = (itemsArray, listName) => {
    var _a, _b, _c;
    const value = ((_c = (_b = (_a = itemsArray === null || itemsArray === void 0 ? void 0 : itemsArray[0]) === null || _a === void 0 ? void 0 : _a.prices) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.itemPrice) || null;
    const items = (0, helpers_1.formItemsArray)(itemsArray, listName);
    if (items.length > 0) {
        items[0] = Object.assign(Object.assign({}, items[0]), { price: value });
    }
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.SELECT_ITEM,
        ecommerce: {
            item_list_name: listName,
            items
        }
    });
};
exports.selectItemEvent = selectItemEvent;
const viewItemListEvent = (itemsArray, listName) => {
    (0, helpers_1.pushEvent)({
        event: types_1.EDataLayerEventsNames.VIEW_ITEM_LIST,
        ecommerce: {
            item_list_name: listName,
            items: (0, helpers_1.formItemsArray)(itemsArray, listName, false, true)
        }
    });
};
exports.viewItemListEvent = viewItemListEvent;
const openCompanyDetails = (company) => {
    (0, helpers_1.pushPartnerFinderEvenet)({
        event: types_1.EPartnersFindersEvenetsNames.PF_MOPRE_INFO,
        company_id: company.pageId,
        country_id: company.country,
        city_id: company.city
    });
};
exports.openCompanyDetails = openCompanyDetails;
const openCompanyDetailsMap = (company) => {
    (0, helpers_1.pushPartnerFinderEvenet)({
        event: types_1.EPartnersFindersEvenetsNames.PF_MAP_CLICK,
        company_id: company.pageId,
        country_id: company.country,
        city_id: company.city
    });
};
exports.openCompanyDetailsMap = openCompanyDetailsMap;
const searchPartnerFinder = (value) => {
    (0, helpers_1.pushPartnerFinderEvenet)({
        event: types_1.EPartnersFindersEvenetsNames.PF_SEARCH,
        pf_search_term: value
    });
};
exports.searchPartnerFinder = searchPartnerFinder;
const filterPartnerFinder = (value) => {
    (0, helpers_1.pushPartnerFinderEvenet)({
        event: types_1.EPartnersFindersEvenetsNames.PF_FILTER,
        filterinput: value
    });
};
exports.filterPartnerFinder = filterPartnerFinder;
const superStoreClick = () => {
    (0, helpers_1.pushPartnerFinderEvenet)({
        event: types_1.EPartnersFindersEvenetsNames.SUPERSTORE_CLICK,
    });
};
exports.superStoreClick = superStoreClick;
