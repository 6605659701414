"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPartnersFindersEvenetsNames = exports.EDataLayerEventsNames = void 0;
var EDataLayerEventsNames;
(function (EDataLayerEventsNames) {
    EDataLayerEventsNames["ADD_TO_CART"] = "add_to_cart";
    EDataLayerEventsNames["ADD_SHIPPING_INFO"] = "add_shipping_info";
    EDataLayerEventsNames["BEGIN_CHECKOUT"] = "begin_checkout";
    EDataLayerEventsNames["VIEW_CART"] = "view_cart";
    EDataLayerEventsNames["PURCHASE"] = "purchase";
    EDataLayerEventsNames["VIEW_ITEM"] = "view_item";
    EDataLayerEventsNames["SELECT_ITEM"] = "select_item";
    EDataLayerEventsNames["REMOVE_FROM_CART"] = "remove_from_cart";
    EDataLayerEventsNames["VIEW_ITEM_LIST"] = "view_item_list";
})(EDataLayerEventsNames = exports.EDataLayerEventsNames || (exports.EDataLayerEventsNames = {}));
var EPartnersFindersEvenetsNames;
(function (EPartnersFindersEvenetsNames) {
    EPartnersFindersEvenetsNames["PF_MOPRE_INFO"] = "pfMoreInfo";
    EPartnersFindersEvenetsNames["PF_MAP_CLICK"] = "pfMapClick";
    EPartnersFindersEvenetsNames["PF_SEARCH"] = "pfSearch";
    EPartnersFindersEvenetsNames["PF_FILTER"] = "pfFilter";
    EPartnersFindersEvenetsNames["SUPERSTORE_CLICK"] = "superstore_click";
})(EPartnersFindersEvenetsNames = exports.EPartnersFindersEvenetsNames || (exports.EPartnersFindersEvenetsNames = {}));
